(function () {
  'use strict';

  angular.module('app.common').service('SeasonService', SeasonService);

  var SEASON_START_DATE = moment('07/01', 'MM-DD');

  // used by datahub when we want to switch to new season earlier than the normal start_date
  var SEASON_EARLY_START_DATE = moment('05/11', 'MM-DD');

  function SeasonService($q) {
    let _seasonId = null;
    let _seasonReadyListeners = [];

    function getSeasonId() {
      if (_seasonId) return _seasonId;

      _seasonId = localStorage.getItem('selectedSeasonId');
      return _seasonId;
    }

    function getSeasonName() {
      // seasonId at the moment is the season name
      // 20204-2025 for multiple years season
      // 2024 for single year season
      return getSeasonId();
    }

    function setSeasonId(seasonId) {
      _seasonId = seasonId;
      localStorage.setItem('selectedSeasonId', seasonId);

      _seasonReadyListeners.forEach((listener) => {
        listener.resolve(seasonId);
      });
      _seasonReadyListeners = [];
    }

    this.getSeasonId = getSeasonId;
    this.setSeasonId = setSeasonId;
    this.getSeasonName = getSeasonName;

    this.waitForSeason = function () {
      if (this.getSeasonId()) {
        return $q.resolve(this.getSeasonId());
      }

      const deferred = $q.defer();
      _seasonReadyListeners.push(deferred);

      return deferred.promise;
    };

    this.toOurSeason = function (seasonId) {
      seasonId = seasonId ? seasonId : this.getSeasonId();

      // multiple years season
      if (seasonId.includes('-')) {
        const parts = seasonId.split('-');
        const startYear = parts[0];
        const endYear = parts[1];
        return [`${startYear}`.substring(2), `${endYear}`.substring(2)].join('');
      }

      // single year season
      return `s${seasonId}`;
    };

    /**
     * TODO: this is still referenced in a couple of places (ie. Advanced Search Free Agents & Competition Controller)
     * Not clear how to update this logic to work with the single year season yet, because it's something league-specific
     */
    this.getCurrentSeasonLegacy = function (shouldUseEarlyStartDate) {
      // default var initialization does not work with ng-annotate: https://newbedev.com/ngannotate-warning-stringmap-expected-string-key
      shouldUseEarlyStartDate =
        shouldUseEarlyStartDate === undefined ? false : shouldUseEarlyStartDate;
      var currentYear = moment().year();
      var seasonStartDate = shouldUseEarlyStartDate
        ? moment(SEASON_EARLY_START_DATE).year(currentYear)
        : moment(SEASON_START_DATE).year(currentYear);

      return moment().isBefore(seasonStartDate) ? currentYear - 1 : currentYear;
    };

    /**
     * Leagcy season only care about the startYear
     * Therefore can not be used precisedly to represent the single year season.
     *
     * For now keep it here, to existing places keep working, until we know more
     * what todo with the single year season
     */
    this.getSeasonLegacy = function () {
      const seasonId = this.getSeasonId();
      if (!seasonId) {
        return null;
      }

      if (seasonId.includes('-')) {
        const parts = seasonId.split('-');
        const startYear = parts[0];
        return Number(startYear);
      }

      return Number(seasonId);
    };

    /**
     * Still needed for create new game in game-selector.ts
     */
    this.getSeasonDatesLegacy = function (year) {
      return {
        start: moment(SEASON_START_DATE).year(year).toDate(),
        end: moment(SEASON_START_DATE)
          .year(year + 1)
          .subtract(1, 'days')
          .toDate(),
      };
    };

    /**
     * Legacy season - should be removed
     */

    this.getSeason = function () {
      throw new Error('getSeason no longer supported');
      // return legacySeasonData.season;
    };

    this.getSeasonFromDate = function (dateStr) {
      var date = moment(dateStr, 'YYYY-MM-DD');
      var year = date.year();
      var currentYearStartDate = moment(SEASON_START_DATE).year(year);

      if (date.isAfter(currentYearStartDate)) {
        return year;
      }

      return year - 1;
    };

    this.getSeasonList = function (pastSeasonsOffset) {
      // throw new Error('getSeasonList no longer supported');
      var currentSeason = this.getCurrentSeasonLegacy();
      var list = [];

      pastSeasonsOffset =
        pastSeasonsOffset !== undefined && pastSeasonsOffset !== null ? pastSeasonsOffset : 9;

      for (var season = currentSeason + 1; season > currentSeason - pastSeasonsOffset; --season) {
        list.push({
          name: season,
          display: season + '/' + (season + 1),
        });
      }

      return list;
    };

    this.generateSeasonList = function (startYear, endYear) {
      throw new Error('generateSeasonList no longer supported');
      // if (!startYear || !endYear) {
      //   throw new Error('SeasonService.generateSeasonList: missing params');
      // }

      // var seasons = [];

      // for (var i = endYear; i >= startYear; i--) {
      //   seasons.push({
      //     name: i,
      //     display: i + '/' + (i + 1),
      //   });
      // }
      // return seasons;
    };
  }
})();
